
.card img {
  width: 100%;
  object-fit: fill;
}
.card:hover {
	transform: translateY(-10px);
	transition: all .2s linear;
	background: #DFD9CC;
	border-radius: 20px;
	box-shadow: 2px 6px 1px rgb(0 0 0 / 7%);
}
.card:hover .tag {
	background: #7D5D43;
	transition: all .2s linear;
	color: #FFEEEE;
}
.card:hover p {
	color: #7D5D43;
	font-weight: 700;
	transition: all .2s linear;
}