.category-card-group {
  margin-bottom: 24px;
  gap: 8px;
}
.category-card-group .tag {
  padding: 8px;
  gap: 8px;
}
.category-card-group .tag:hover {
	transition: all .2s linear;
  color: #FFF;
  background: #ad7a50;
}
.category-card-group .card-content {
  width: calc(100% - 58px);
}
.category-card-group .tag div:first-of-type {
  width: 50px;
  height: 50px;
}
.category-card-group .tag p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 2rem;
  height: 4rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}