.page-title {
  z-index: 2;
  font-size: 36px;
  position: relative;
  display: inline-block;
}
.page-title {
  --show-font: '',
  --show-deco: '';
  color: var(--show-font);
  /* color: #025791; */
}
.page-title:after {
  background-color: var(--show-deco);
  /* background-color: #55d2f9; */
}
.page-title:after {
  content: "";
  position: absolute;
  height: 15px;
  width: 102%;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}